import { useState } from "react";
import { Button, Popover, List, Drawer } from "@mui/material";
import clsx from "clsx";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import DashBoardSideNav from "../navigation/DashBoardSideNav";
import zIndex from "@mui/material/styles/zIndex";
import Copyright from "./Copyright";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    flexGrow: 1,
    backgroundColor: "#595a5c",
    whiteSpace: "nowrap",
    width: 300,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
}));

const DrawerComponent = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        open={open}
        style={{
          backgroundColor: "#595a5c",
          marginRight: 70,
        }}
        anchor="left"
      >
        <List>
          <DashBoardSideNav setDrawerOpen={setOpen} isOpen={open} />
          {open && <Copyright />}
        </List>
      </Drawer>
    </>
  );
};

export default DrawerComponent;
