import React, { useState, useEffect } from "react";
import {
  OpenRefreshTokenDialogVar,
  isLoggedInVar,
  userVar,
  filterListVar,
  selectedDateVar,
  initialScheduleStartDateVar,
  newScheduleStartDateVar,
} from "../../cache";
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { signOut, getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { useApolloClient } from "@apollo/client";
import { useIdleTimer } from "react-idle-timer";

async function signout() {
  try {
    await signOut({ global: true });
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

export const handleLogout = (client) => {
  signout();
  OpenRefreshTokenDialogVar(false);
  isLoggedInVar(false);
  userVar({});
  selectedDateVar(new Date());
  initialScheduleStartDateVar(null);
  newScheduleStartDateVar(false);
  sessionStorage.removeItem("jwt");
  sessionStorage.clear("jwt");
  localStorage.clear();
  if (client) {
    client.cache.reset();
  }
};

const LogOutDialog = (props) => {
  const { openRefreshDialog, setOpenRefreshDialog } = props;
  const client = useApolloClient();

  let intervalStopper;

  const startIntervalTime = () => {
    console.log("START INTERVAL");
    intervalStopper = setInterval(
      () => {
        handleLogout(client);
        clearInterval(intervalStopper);
      },
      1000 * 60 * 5,
    );
  };

  const stopIntervalTime = () => {
    console.log("STOP INTERVAL");
    clearInterval(intervalStopper);
  };

  if (openRefreshDialog == true) {
    console.log("GOING IN");
    startIntervalTime();
  }

  return (
    <div>
      <Dialog open={openRefreshDialog === true} fullWidth maxWidth="sm">
        <DialogContent
          style={{
            padding: 40,
            overflowX: "hidden",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <Typography
            variant="subtitle1"
            style={{ textAlign: "center", marginBottom: 40 }}
          >
            There has been no activity for 20 minutes.
          </Typography>
          <Grid style={{ textAlign: "center" }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                stopIntervalTime();
                handleLogout(client);
              }}
              style={{ marginRight: 30 }}
            >
              Log Out{" "}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                stopIntervalTime();
                setOpenRefreshDialog(false);
              }}
            >
              {" "}
              Stay Logged In
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LogOutDialog;
