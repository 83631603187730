import React, { useEffect, useState } from "react";
import LoginForm from "../userAuthentication/LoginForm";
import ForgotPassword from "../userAuthentication/ForgotPassword";
import SAMLLogin from "../userAuthentication/SAMLLogin";
import {
  Container,
  Toolbar,
  CssBaseline,
  Box,
  Link,
  AppBar,
  Alert,
  Typography,
  Snackbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Switch, Route, useHistory } from "react-router-dom";
import { PUBLICLOCATION } from "../../api/gqlQueries";
import { useApolloClient, useQuery } from "@apollo/client";
import { appsettingsVar, isLoggedInVar, userVar } from "../../cache";
import VerifyCode from "../userAuthentication/verifyCode";
import { handleLogout } from "./LogOutDialog";
import UpdatePassword from "../userAuthentication/UpdatePassword";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { confirmSignIn, getCurrentUser, signIn } from "aws-amplify/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
}));

let toastObj;

function toastShow(content, type) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

function UnauthenticatedApp({ errored = false }) {
  const history = useHistory();
  const classes = useStyles();
  const client = useApolloClient();
  const locationSettings = useQuery(PUBLICLOCATION, {
    onError(error) {
      console.error(error);
    },
  });
  const [logourl, setlogourl] = useState("");
  const [logoErr, setLogoErr] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const appSettings = appsettingsVar();

  const currentLogo =
    !locationSettings.loading &&
    locationSettings.data &&
    locationSettings.data.locationPublicSettings[0].logo;
  const baseURL = window.location.origin;
  const environment = process.env.NODE_ENV;
  appSettings.logo = currentLogo;

  let url = document.URL;
  url = url.split("//")[1];
  let urlLink = url.includes("/") && url.split("/")[1];
  if (
    urlLink &&
    urlLink.length > 0 &&
    urlLink != "ForgotPassword" &&
    urlLink != "FirstTimeUser" &&
    urlLink != "VerifyCode"
  ) {
    history.push("/");
  }

  useEffect(() => {
    if (errored) {
      handleLogout(client);

      //   show toast message to user
      setShowToast(true);
    }
  }, []);

  useEffect(() => {
    const currentLogo =
      !locationSettings.loading &&
      locationSettings.data &&
      locationSettings.data.locationPublicSettings[0].logo;
    const baseURL = window.location.origin;
    const environment = process.env.NODE_ENV;
    appSettings.logo = currentLogo;
    if (environment === "development") {
      setlogourl("https://backendtest.balancescheduler.com" + appSettings.logo);
    } else if (baseURL.includes("amplify")) {
      setlogourl(appSettings.logo);
    } else {
      setlogourl(appSettings.logo);
    }
  }, [!locationSettings.loading]);

  useEffect(() => {
    const currentLogo =
      !locationSettings.loading &&
      locationSettings.data &&
      locationSettings.data.locationPublicSettings[0].logo;
    const baseURL = window.location.origin;
    const environment = process.env.NODE_ENV;
    appSettings.logo = currentLogo;
    if (environment === "development") {
      setlogourl("https://backendtest.balancescheduler.com" + appSettings.logo);
    } else if (baseURL.includes("amplify")) {
      setlogourl(appSettings.logo);
    } else {
      setlogourl(appSettings.logo);
    }
  }, [appSettings.logo]);

  async function handleUpdatePassword(oldPassword, newPassword) {
    try {
      const username = sessionStorage.getItem("username");

      const { isSignedIn, nextStep } = await signIn({
        username: username,
        password: oldPassword,
      });

      if (
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        const { isSignedIn, nextStep } = await confirmSignIn({
          challengeResponse: newPassword,
        });
        // Post-update actions if the password update was successful
        isLoggedInVar(false);
        userVar({});
        sessionStorage.removeItem("jwt");
        sessionStorage.clear("jwt");
        client.cache.reset();
        history.push("/");
        toastShow("Password successfully updated", "Success");
      }
    } catch (err) {
      console.log("ERROR", err);
      if (err.toString().includes("InvalidPasswordException")) {
        toastShow(err.toString().split(":")[2], "Error");
      } else if (err.toString().includes("NotAuthorizedException")) {
        toastShow(err.toString().split(":")[1], "Error");
      } else if (err.toString().includes("LimitExceededException")) {
        toastShow(err.toString().split(":")[1], "Error");
      } else {
        toastShow("Could not update password, please try again", "Error");
      }
    }
  }

  if (locationSettings.loading) {
    return <div>loading...</div>;
  } else {
    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer && classes.root}>
          <Container>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar)}>
              <Toolbar className={classes.toolbar}>
                <Box component={Link} to="/">
                  <img
                    src={window.LOGO}
                    height={75}
                    alt={window.LOGO.split(".")[0]
                      .split("-")
                      .join(" ")
                      .concat(" logo")}
                  />
                </Box>
              </Toolbar>
            </AppBar>
            <Box>
              <Switch>
                <Route path="/" exact component={LoginForm} />
                <Route
                  path="/FirstTimeUser"
                  exact
                  component={() => (
                    <UpdatePassword handleSubmit={handleUpdatePassword} />
                  )}
                />
                <Route
                  path="/ForgotPassword"
                  exact
                  component={ForgotPassword}
                />
                <Route path="/VerifyCode" exact component={VerifyCode} />
                <Route path="/saml2/login" exact component={SAMLLogin} />
                <Route path="/saml2/unauthorized">
                  {(props) => <LoginForm {...props} error={true} />}
                </Route>
              </Switch>
            </Box>
          </Container>
        </div>
        <Snackbar
          open={showToast}
          autoHideDuration={3000}
          onClose={() => setShowToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            elevation={6}
            onClose={() => setShowToast(false)}
            severity="error"
          >
            <Typography>An error occurred, please try again</Typography>
          </Alert>
        </Snackbar>
      </main>
    );
  }
}

export default UnauthenticatedApp;
