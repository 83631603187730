import { format } from "date-fns";
import { userVar } from "../../cache";
import { formatTime } from "../../helpers/formatTime";

function transformFilterSkills(skillObject) {
  return `,${Object.keys(skillObject).join(",")},`;
}

function transformSkillNames(skillObject) {
  return Object.values(skillObject).join(", ");
}

function reduceSlackSkills(shiftAssignment) {
  /**
   * Return the set of unique skills covered during a shift assignment.
   *
   * Depending on the office, a shift may be split into multiple intervals for demand
   * tracking purposes. As a result, the same skill may be in the `skillSet` array many
   * times.
   *
   * @param {Object} shiftAssignment represents an EfficientShiftAssignmentNode with a `skillSet` array
   *
   * @returns {Object} map of PK to the skill name
   */
  let out = {};
  shiftAssignment.skillSet.forEach((skillNode) => {
    out[skillNode.skillId] = skillNode.name;
  });
  return out;
}

function monthTransformer(data) {
  let outputArray = [];

  data.forEach((record) => {
    let start = new Date(record.start);
    let end = new Date(record.end);
    outputArray.push({
      id: "SHIFT:" + record.id,
      start: start,
      end: end,
      subject: "",
      officeName: record.officeName,
      isAllDay: false,
      description: record.shiftTypeName,
      eventType: "SHIFT",
      participants: record.shiftassignmentSet,
      shiftType: record.shiftTypeName,
      shiftId: record.id,
      rootShiftId: record.rootShiftId,
      parentShiftId: record.parentShiftId,
      procedureId: record.procedureId,
      procedureName: record.procedureName,
      procedureType: record.procedureType,
      workHours: record.workHours,
      officeResource: record.officeName,
      employeeResource: null,
      shiftResource: "TBD",
    });

    if (record.shiftassignmentSet.length > 0) {
      record.shiftassignmentSet.forEach((shiftAssignment) => {
        const skillObj = reduceSlackSkills(shiftAssignment);
        outputArray.push({
          id: "SHIFTASSIGNMENT:" + shiftAssignment.id,
          start: start,
          end: end,
          subject: "",
          officeName: record.officeName,
          isAllDay: false,
          description: transformSkillNames(skillObj),
          eventType: "SHIFTASSIGNMENT",
          employee: {
            id: shiftAssignment.employeeId,
            firstName: shiftAssignment.firstName,
            lastName: shiftAssignment.lastName,
            workHours: shiftAssignment.workHours
              ? shiftAssignment.workHours
              : record.workHours,
            skillSet: shiftAssignment.skillSet,
            shiftAssignmentId: "" + shiftAssignment.id,
            filterSkills: transformFilterSkills(skillObj),
          },
          participants: record.shiftassignmentSet,
          shiftType: record.shiftTypeName,
          shiftId: record.id,
          rootShiftId: record.rootShiftId,
          parentShiftId: record.parentShiftId,
          procedureId: record.procedureId,
          procedureName: record.procedureName,
          procedureType: record.procedureType,
          workHours: record.workHours,
          officeResource: record.officeName,
          employeeResource: shiftAssignment.employeeId,
          shiftResource: "TBD",
          callIn: shiftAssignment.callIn,
        });
      });
    }
  });

  return outputArray;
}

function employeeTransformer(data) {
  /*
  Creates a list of shiftAssignments that are prepped to be added to the EmployeeTimeline view
  inputs:
      data:
        type: array
        content: results from the EFFICIENT_SQL_SHIFT_ASSIGNMENTS if environment var DISPLAY_ALL_OFFICE_EVENTS
                is false. ALL_SQL_SHIFT_ASSIGNMENTS if the environment variable is true
  return:
    outputArray: array of shiftAssignments with the below assignments for each input record

  */
  let outputArray = [];
  for (const shift of data) {
    for (const assignment of shift.shiftassignmentSet) {
      const skillObj = reduceSlackSkills(assignment);
      outputArray.push({
        id: `SHIFTASSIGNMENT:${assignment.id}`,
        start: new Date(shift.start),
        end: new Date(shift.end),
        subject: "",
        officeName: shift.officeName,
        isAllDay: false,
        description: transformSkillNames(skillObj),
        eventType: "SHIFTASSIGNMENT",
        employee: {
          id: assignment.employeeId,
          firstName: assignment.firstName,
          lastName: assignment.lastName,
          workHours: assignment.workHours
            ? assignment.workHours
            : shift.workHours,
          skillSet: assignment.skillSet,
          shiftAssignmentId: assignment.id,
          filterSkills: transformFilterSkills(skillObj),
        },
        participants: shift.shiftassignmentSet,
        shiftType: shift.shiftTypeName,
        shiftId: shift.id,
        rootShiftId: shift.rootShiftId,
        parentShiftId: shift.parentShiftId,
        procedureId: shift.procedureId,
        procedureName: shift.procedureName,
        procedureType: shift.procedureType,
        workHours: shift.workHours,
        officeResource: shift.officeName,
        employeeResource: assignment.employeeId,
        shiftResource: "TBD",
        callIn: assignment.callIn,
      });
    }
  }
  return outputArray;
}

function shiftTimelineTransformer(data) {
  /**
  Creates a list of shifts, andshiftAssignments that are prepped to be added to the ShiftTimeleine view
  inputs:
      data:
        type: array
        content: results from the EFFICIENT_SQL_SHIFT_ASSIGNMENTS if environment var DISPLAY_ALL_OFFICE_EVENTS
                is false. ALL_SQL_SHIFT_ASSIGNMENTS if the environment variable is true
  return:
    outputArray:
      type: array
      content: array of shifts, and shiftAssignments with the below assignments for each input record
  */
  let outputArray = [];
  let shiftItemIds = {};
  data.forEach((record) => {
    let start = new Date(record.start);
    let end = new Date(record.end);
    if (record.shiftassignmentSet.length > 0) {
      record.shiftassignmentSet.forEach((shiftAssignment) => {
        const skillObj = reduceSlackSkills(shiftAssignment);
        outputArray.push({
          id: "SHIFTASSIGNMENT:" + shiftAssignment.id,
          start: start,
          end: end,
          subject: "",
          officeName: record.officeName,
          isAllDay: false,
          description: transformSkillNames(skillObj),
          eventType: "SHIFTASSIGNMENT",
          employee: {
            id: shiftAssignment.employeeId,
            firstName: shiftAssignment.firstName,
            lastName: shiftAssignment.lastName,
            workHours: shiftAssignment.workHours
              ? shiftAssignment.workHours
              : record.workHours,
            skillSet: shiftAssignment.skillSet,
            shiftAssignmentId: "" + shiftAssignment.id,
            filterSkills: transformFilterSkills(skillObj),
          },
          participants: record.shiftassignmentSet,
          shiftType: record.shiftTypeName,
          shiftId: record.id,
          rootShiftId: record.rootShiftId,
          parentShiftId: record.parentShiftId,
          procedureId: record.procedureId,
          procedureName: record.procedureName,
          procedureType: record.procedureType,
          workHours: record.workHours,
          officeResource: record.officeName,
          employeeResource: shiftAssignment.employeeId,
          shiftResource: "TBD",
          callIn: shiftAssignment.callIn,
        });
      });
    } else if (userVar().isEmployee === false) {
      outputArray.push({
        id: "SHIFT:" + record.id,
        start: start,
        end: end,
        subject: "",
        officeName: record.officeName,
        isAllDay: false,
        description: record.shiftTypeName,
        eventType: "SHIFT",
        participants: record.shiftassignmentSet,
        shiftType: record.shiftTypeName,
        shiftId: record.id,
        rootShiftId: record.rootShiftId,
        parentShiftId: record.parentShiftId,
        procedureId: record.procedureId,
        procedureName: record.procedureName,
        procedureType: record.procedureType,
        workHours: record.workHours,
        officeResource: record.officeName,
        employeeResource: null,
        shiftResource: null,
      });
    }
  });
  return outputArray;
}

function manualSchedulingTransformer(data) {
  /**
   * Transforms db data into data ready for the syncfusion Scheduler component.
   *
   * Transforms an array of EfficientShiftNode into ShiftAssignment and Shift events.
   * The Shift events are only created if there are no employees assigned to the shift.
   *
   * @param {EfficientShiftNode[]} data array of data from the sqlShiftAssignments2 query.
   *
   * @returns {Object[]} an array of Shift and ShiftAssignment event data objects.
   */
  let output = [];
  let rootDefinitions = {};
  let isSplit = false;
  data.forEach((shift) => {
    if (shift.rootShiftId == null) {
      rootDefinitions[shift.id] = shift.shiftDefinitionId;
    } else {
      isSplit = true;
    }
    if (shift.shiftassignmentSet.length > 0) {
      shift.shiftassignmentSet.forEach((sa) => {
        const skillObj = reduceSlackSkills(sa);
        output.push({
          id: `SHIFTASSIGNMENT:${sa.id}`,
          start: new Date(shift.start),
          end: new Date(shift.end),
          subject: "",
          officeName: shift.officeName,
          isAllDay: false,
          description: transformSkillNames(skillObj),
          eventType: "SHIFTASSIGNMENT",
          employee: {
            id: sa.employeeId,
            firstName: sa.firstName,
            lastName: sa.lastName,
            workHours: sa.workHours ? sa.workHours : shift.workHours,
            skillSet: sa.skillSet,
            shiftAssignmentId: sa.id,
            filterSkills: transformFilterSkills(skillObj),
          },
          participants: shift.shiftassignmentSet,
          shiftType: shift.shiftTypeName,
          shiftId: shift.id,
          rootShiftId: shift.rootShiftId,
          parentShiftId: shift.parentShiftId,
          procedureId: shift.procedureId,
          procedureName: shift.procedureName,
          procedureType: shift.procedureType,
          workHours: shift.workHours,
          officeResource: shift.officeName,
          employeeResource: null,
          shiftResource: shift.shiftDefinitionId,
          callIn: sa.callIn,
        });
      });
    } else if (userVar().isEmployee === false) {
      output.push({
        id: `SHIFT:${shift.id}`,
        start: new Date(shift.start),
        end: new Date(shift.end),
        subject: "",
        officeName: shift.officeName,
        isAllDay: false,
        description: shift.shiftTypeName,
        eventType: "SHIFT",
        employee: null,
        participants: shift.shiftassignmentSet,
        shiftType: shift.shiftTypeName,
        shiftId: shift.id,
        rootShiftId: shift.rootShiftId,
        parentShiftId: shift.parentShiftId,
        procedureId: shift.procedureId,
        procedureName: shift.procedureName,
        procedureType: shift.procedureType,
        workHours: shift.workHours,
        officeResource: shift.officeName,
        employeeResource: null,
        shiftResource: shift.shiftDefinitionId,
      });
    }
  });
  if (isSplit) {
    output.forEach((event) => {
      if (event.shiftResource == null && event.rootShiftId != null) {
        event.shiftResource = rootDefinitions[event.rootShiftId]
          ? rootDefinitions[event.rootShiftId]
          : null;
      }
    });
  }
  return output;
}

function defaultTransformer(data) {
  return [];
}

export function getScheduleDataTransformer(viewName, viewIndex) {
  /**
  Takes in a string of the current view, and the view index. Returns the transform function
  that needs to be used given those constraints,
  inputs:
      viewName:
        type: string
        content: the current calendar view
      viewIndex:
        type: int
        content: the current view index
  return:
    transform function that needs to be used based on the given context

  */
  switch (viewName) {
    case "Month":
      return monthTransformer;
    case "TimelineDay":
      if (viewIndex === 1) {
        return employeeTransformer;
      }
      return shiftTimelineTransformer;
    case "TimelineWeek":
      return manualSchedulingTransformer;
    default:
      return defaultTransformer;
  }
}

function transformSlack(data) {
  const events = [...data];

  const formattedSlacks = {};

  for (var event of events) {
    var eventDate = format(new Date(event.interval.start), "MM/dd/yyyy");
    if (!formattedSlacks[eventDate]) {
      formattedSlacks[eventDate] = {};
    }
    formattedSlacks[eventDate][event.id] = event;
  }

  return formattedSlacks;
}

export function getSlackTransformer(viewName, viewIndex) {
  return transformSlack;
}

export function transformTimeOffv2(data) {
  /**
   * Transform EmployeeTimeOff objects from the EMPLOYEE_TIME_OFF query
   *
   * returns an array of transformed data to be applied to the calendar
   *
   * @param {Array[EmployeeTimeOff]} data the input data array
   *
   * @return {Array} of objects with TimeOff attributes that will be applied to the calendar
   */
  if (!data) {
    return [];
  }
  let outputArray = [];
  data.map((request) => {
    if (request.status !== "DENIED" && request.status !== "CANCELED") {
      const newRequest = {
        id: `EMPLOYEETIMEOFF:${request.id}`,
        start: new Date(request.start),
        end: new Date(request.end),
        officeName: request.office.name,
        description: request.type.name,
        eventType: "EMPLOYEETIMEOFF",
        eventId: request.id,
        employee: request.employee,
        type: request.type,
        isAllDay: request.isAllDay,
        isApproved: request.status === "APPROVED",
        status: request.status,
        resolvedBy: request.resolvedBy,
        workHours: request.workHours,
        comment: !request.comment ? "" : request.comment,
        officeResource: "Requests",
        employeeResource: request.employee.id,
        shiftResource: "Requests",
        version: request.version,
      };
      outputArray.push(newRequest);
    }
  });
  return outputArray;
}

export function getTimeOffTransformer(viewName, viewIndex) {
  /**
   * Returns the appropriate timeOff transformation function given the provided context
   *
   * @param {String} viewName the name of the current SyncFusion view
   */

  return transformTimeOffv2;
}

function employeeOnCallTransformer(data, userId) {
  /**
   * Transform OnCall objects from the GET_EMPLOYEE_ON_CALL query
   *
   * Returns an array of transformed data to be applied to the calendar
   *
   * @param {Array[EmployeeOnCallNode]} data the result from the graphQL query
   *
   * @return {Array[Object]} the objects that will be applied to the calendar
   */
  if (!data) {
    return [];
  } else {
    return data.map((onCall) => {
      const newOnCall = {
        id: `ONCALL:${onCall.id}`,
        eventId: onCall.id,
        start: new Date(onCall.start),
        end: new Date(onCall.end),
        subject: "On Call",
        officeName: onCall.office.name,
        isAllDay: false,
        description: "ONCALL",
        eventType: "ONCALL",
        employee: onCall.employee,
        officeResource: "On Call",
        employeeResource: onCall.employee.id,
        shiftResource: "On Call",
      };
      return newOnCall;
    });
  }
}

function onCallTransformer(data, userId) {
  /**
   * Transform OnCall objects from the GET_EMPLOYEE_ON_CALL query
   *
   * Returns an array of transformed data to be applied to the calendar
   *
   * @param {Array[EmployeeOnCallNode]} data the result from the graphQL query
   *
   * @return {Array[Object]} the objects that will be applied to the calendar
   */
  if (!data) {
    return [];
  } else {
    return data.map((onCall) => {
      const newOnCall = {
        id: `ONCALL:${onCall.id}`,
        eventId: onCall.id,
        start: new Date(onCall.start),
        end: new Date(onCall.end),
        subject: `${onCall.employee.firstName} ${onCall.employee.lastName} - On Call`,
        officeName: onCall.office.name,
        isAllDay: false,
        description: "ONCALL",
        eventType: "ONCALL",
        employee: onCall.employee,
        officeResource: "On Call",
        employeeResource: onCall.employee.id,
        shiftResource: "On Call",
      };
      return newOnCall;
    });
  }
}

export function getOnCallTransformer(viewName, viewIndex) {
  switch (viewName) {
    case "TimelineDay":
      if (viewIndex === 1) {
        return employeeOnCallTransformer;
      }
      return onCallTransformer;
    default:
      return onCallTransformer;
  }
}

function transformSoftRequests(data, employee) {
  /**
   * Transorms the SoftRequest objects from GET_ALL_SOFT_REQUESTS query
   *
   * Returns an array of transformed data to be applied to the calendar
   *
   * @param {Array[OffPreferenceNode]} data the result from the graphQL query
   * @param {int} employee the primary key of the currently logged in employee
   *
   * @return {Array[Object]} the objects that will be applied to the calendar
   *
   */
  if (!data) {
    return [];
  }
  let outputArray = [];
  data.forEach((request) => {
    outputArray.push({
      id: "OFFPREFERENCE:" + request.id,
      start: new Date(request.date + "T08:00+00:00"),
      end: new Date(request.date + "T09:00+00:00"),
      subject: "Requested Off",
      isAllDay: true,
      displayAllDay: true,
      description: request.value > 5 ? "Priority request" : "Normal request",
      eventType: "OFFPREFERENCE",
      eventId: request.id,
      employee: { id: employee },
      highPriority: request.value > 5,
      officeResource: "Requests",
      employeeResource: employee,
      shiftResource: "On Call",
    });
  });

  return outputArray;
}

export function getSoftRequestTransformer(viewName, viewIndex) {
  return transformSoftRequests;
}
