import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import { Tooltip } from "@mui/material";

export default function YupValTooltip({
  error,
  tooltipStyle,
  warningStyle,
  testid,
}: {
  error: string;
  tooltipStyle: string;
  warningStyle: string;
  testid: string;
}) {
  return (
    <Tooltip
      title={error}
      placement="left"
      arrow
      enterDelay={500}
      enterNextDelay={500}
      classes={{
        tooltip: tooltipStyle,
      }}
    >
      <span>
        <WarningIcon data-testid={testid} className={warningStyle} />
      </span>
    </Tooltip>
  );
}
