import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  formatShortTime as localShortTime,
  formatMilitaryTime as localMilitaryTime,
  formatTime,
  formatDuration,
} from "../../helpers/formatTime";
import { formatRange } from "../../helpers/FormatDate.ts";
import { userVar } from "../../cache";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    minWidth: 450,
    backgroundColor: "rgba(57, 57, 60, 0.95)",
  },
  label: {
    height: "auto",
    wordWrap: "break-word",
  },
}));

function formatNameLastFI(employee) {
  if (employee.firstName && employee.lastName) {
    // common case
    return `${employee.lastName}, ${employee.firstName[0]}.`;
  } else if (employee.lastName) {
    return employee.lastName;
  } else {
    return `Employee ${employee.id}`;
  }
}

function formatNameFirstLast(employee) {
  if (employee.firstName && employee.lastName) {
    // common case
    return `${employee.firstName} ${employee.lastName}`;
  } else if (employee.lastName) {
    return employee.lastName;
  } else {
    return `Employee ${employee.id}`;
  }
}

export function ShiftTemplate({
  props,
  view,
  env,
  cmo,
  formatShortTime = localShortTime,
  formatMilitaryTime = localMilitaryTime,
}) {
  const classes = useStyles();
  const user = userVar();
  let subject;
  if (env.SHOW_MILITARY_TIME === "true") {
    subject = formatMilitaryTime(props.start, props.end);
  } else {
    subject = formatShortTime(props.start, props.end);
  }
  if (view === "Month") {
    if (cmo || env.DISPLAY_ALL_OFFICES_EVENTS === "true") {
      subject = `${subject} ${props.officeName}`;
    }
    subject = `${subject} (${props.participants.length})`;
  }
  let personal = props.participants
    ? props.participants.find((e) => e.employee.id === user.id) != null
      ? true
      : false
    : props.employee.id === user.id;
  return (
    <div className="template-wrap" data-testid={props.id}>
      {/* {view === "Month" && (
          <Grid item>
            {props.eventType === "SHIFT" && props.shiftType === "Day" ? (
              <LightModeOutlinedIcon
                //alt="day shift"
                //src={sun}
                style={{
                  width: 14,
                  marginRight: 5,
                  marginTop: -1,
                }}
              />
            ) : props.shiftType === "Night" ? (
              <Brightness3Icon
                style={{
                  width: 14,
                  marginRight: 5,
                  marginTop: -1,
                }}
              />
            ) : props.shiftType === "Swing" ? (
              <Clock
                style={{
                  width: 14,
                  marginRight: 5,
                  marginTop: -1,
                }}
              />
            ) : (
              ""
            )}
          </Grid>
        )} */}

      {/* {personal && (
          <Grid item>
            <PersonIcon
              style={{
                width: 14,
                marginLeft: 5,
                marginTop: -1,
              }}
            />
          </Grid>
        )} */}

      <div
        style={{
          fontStyle: props.parentShiftId != null ? "italic" : "none",
          fontWeight: 400,
        }}
      >
        {subject}
      </div>
    </div>
  );
}

export function ShiftAssignmentTemplate({
  props,
  view,
  env,
  cmo,
  formatShortTime = localShortTime,
  formatMilitaryTime = localMilitaryTime,
}) {
  let subject;
  if (view == "Employee Timeline") {
    if (env.SHOW_MILITARY_TIME === "true") {
      subject = formatMilitaryTime(props.start, props.end);
    } else {
      subject = formatShortTime(props.start, props.end);
    }
    if (cmo || env.DISPLAY_ALL_OFFICES_EVENTS === "true") {
      subject = `${subject} ${props.officeName}`;
    }
  } else {
    let title =
      env.SHOW_MILITARY_TIME === "true"
        ? formatMilitaryTime(props.start, props.end)
        : formatShortTime(props.start, props.end);
    if (env.EMPLOYEE_NAME_FORMAT === "First Last") {
      if (props.parentShiftId != null) {
        subject = formatNameFirstLast(props.employee) + " " + title;
      } else {
        subject = formatNameFirstLast(props.employee);
      }
    } else {
      if (props.parentShiftId != null) {
        subject = formatNameLastFI(props.employee) + " " + title;
      } else {
        subject = formatNameLastFI(props.employee);
      }
    }
  }
  return (
    <div className="template-wrap" data-testid={props.id}>
      <div
        style={{
          fontStyle: props.parentShiftId != null ? "italic" : "none",
        }}
      >
        {subject}
      </div>
    </div>
  );
}

export function EmployeeTimeOffTemplate({ props, view, env }) {
  let subject = props.subject;
  if (view === "Shift Timeline") {
    if (env.EMPLOYEE_NAME_FORMAT === "First Last") {
      subject = `${formatNameFirstLast(props.employee)}: ${formatRange(new Date(props.start), new Date(props.end), env)}`;
    } else {
      subject = `${formatNameLastFI(props.employee)}: ${formatRange(new Date(props.start), new Date(props.end), env)}`;
    }
    if (!props.isApproved) {
      subject = `${subject} (Pending)`;
    }
  } else if (view === "Manual Scheduling") {
    if (env.EMPLOYEE_NAME_FORMAT === "First Last") {
      subject = `${formatNameFirstLast(props.employee)}: ${formatRange(new Date(props.start), new Date(props.end), env)}`;
    } else {
      subject = `${formatNameLastFI(props.employee)}: ${formatRange(new Date(props.start), new Date(props.end), env)}`;
    }
    if (!props.isApproved) {
      subject = `${subject} (Pending)`;
    }
  } else if (view == "Employee Timeline") {
    subject = `Out: ${formatRange(new Date(props.start), new Date(props.end), env)}`;
    if (!props.isApproved) {
      subject = `${subject} (Pending)`;
    }
  }
  return (
    <div className="template-wrap" data-testid={props.id}>
      <div>{subject}</div>
    </div>
  );
}

export function OnCallTemplate({ props, view, env }) {
  let subject = props.subject;
  if (view != "Employee Timeline") {
    if (env.EMPLOYEE_NAME_FORMAT === "First Last") {
      subject = formatNameFirstLast(props.employee);
    } else {
      subject = formatNameLastFI(props.employee);
    }
  }
  return (
    <div className="template-wrap" data-testid={props.id}>
      <div>{subject}</div>
    </div>
  );
}

export function SoftRequestTemplate({ props }) {
  return (
    <div className="template-wrap" data-testid={props.id}>
      <div>{props.subject}</div>
    </div>
  );
}
