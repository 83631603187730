import { forwardRef, useState, ForwardedRef } from "react";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Dialog,
  DialogContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ScheduleComponent } from "@syncfusion/ej2-react-schedule";
import { Theme } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import { format, eachDayOfInterval } from "date-fns";
import { useMutation } from "@apollo/client";
import {
  CANCEL_EMPLOYEE_TIME_OFF,
  MANAGER_APPROVE_EMPLOYEE_TIME_OFF,
  MANAGER_DENY_EMPLOYEE_TIME_OFF,
  SLACK_FRAGMENT,
} from "../../api/gqlQueries";
import { updateTimeOffEvent, updateScheduleFromOption } from "./UpdateEvents";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { userVar } from "../../cache";
import { AvailabilityTypeNode, SelectedOffice } from "../../types";
import { transformTimeOffv2, getSlackTransformer } from "./Transformer";
import { DateTimePaginator } from "../../helpers/DateTimePaginator";

let toastObj;

function toastShow(content: string, type: String) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: type === "Success" ? 3000 : 0,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}
const useStyles = makeStyles<Theme>((theme) => ({
  input: {
    minWidth: 138,
    maxWidth: 300,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wideInput: {
    width: 575,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  denialInput: {
    width: "100%",
  },
  textField: {
    width: 50,
  },
  timeField: {
    width: 120,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    width: 75,
  },
  error: {
    color: theme.palette.primary.main,
  },
  helperText: {
    color: theme.palette.text.secondary,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    marginTop: -4,
    paddingLeft: 14,
    paddingRight: 14,
  },
}));

/* retrieve time off on a given date and lets user edit the form
   start date and end date can span over a few days.
   user can select a part of the day or user can set the event to an all day event.
   user can delete their time off requests
*/

interface AvailabilityType {
  id: string;
  name: string;
}

interface EditTimeOffRequestProps {
  closeDialog: () => void;
  timeOffRequestToEdit: any;
  notifyDevelopers: (input: { variables: { message: string } }) => void;
  environment: string;
  timeOffRequestsRefetch: () => void;
  SetAllDateTimeOffs: () => void;
  availabilityTypes: AvailabilityTypeNode[];
  selectedOffice: SelectedOffice;
}

interface SkillNode {
  id: string;
  isActive: boolean;
  name: string;
  skillId: string;
}

interface EmployeeNode {
  id: string;
  firstName: string;
  lastName: string;
}

interface ShiftAssignmentEmployee {
  id: string;
  firstName: string;
  lastName: string;
  workHours: number;
  skillSet: SkillNode[];
  shiftAssignmentId: string;
  filterSkills: string;
}

interface ShiftAssignmentParticipant {
  id: number;
  employeeId: string;
  firstName: string;
  lastName: string;
  employee: string;
  workHours: number;
  skillSet: SkillNode[];
  callIn: boolean;
}

interface ShiftAssignment {
  id: string;
  start: Date;
  end: Date;
  subject: string;
  officeName: string;
  isAllDay: boolean;
  description: string;
  eventType: string;
  employee: ShiftAssignmentEmployee;
  participants: ShiftAssignmentParticipant[];
  shiftType: string;
  shiftId: string;
  rootShiftId: string | null;
  parentShiftId: string | null;
  procedureId: string | null;
  procedureName: string | null;
  procedureType: string | null;
  workHours: number;
  officeResource: string;
  employeeResource: string;
  shiftResource: string;
  callIn: boolean;
  Guid: string;
}

interface EmployeeTimeOff {
  id: string;
  start: Date;
  end: Date;
  officeName: string;
  description: string;
  eventType: string;
  eventId: string;
  employee: EmployeeNode;
  type: AvailabilityType;
  isAllDay: boolean;
  isApproved: boolean;
  status: string;
  resolvedBy: EmployeeNode | null;
  workHours: number;
  comment: string;
  officeResource: string;
  employeeResource: string;
  shiftResource: string;
  version: number;
  Guid: string;
}

interface Office {
  id: string;
  name: string;
}

interface ShiftNode {
  id: string;
  start: string;
  end: string;
  procedure: string;
  office: Office;
}

interface IntervalNode {
  id: string;
  start: string;
  end: string;
  shifts: ShiftNode[];
}

interface SlackNode {
  id: string;
  required: number;
  slack: number;
  numAssigned: number;
  interval: IntervalNode;
  skill: SkillNode;
}

interface SlackEvent {
  [date: string]: {
    [id: string]: SlackNode;
  };
}

interface SlackElement {
  interval: {
    start: string;
    end: string;
  };
}

type EventData = ShiftAssignment | EmployeeTimeOff;

interface CalendarRef extends ScheduleComponent {
  eventsData: EventData[];
  slackEvents: SlackEvent;
}

// todo received props are different from different parents. consolidate in the future
const EditTimeOffRequestForm = forwardRef(
  (props: EditTimeOffRequestProps, ref: ForwardedRef<CalendarRef>) => {
    const classes = useStyles();
    const user = userVar();
    const {
      closeDialog,
      timeOffRequestToEdit,
      notifyDevelopers,
      environment,
      timeOffRequestsRefetch,
      SetAllDateTimeOffs,
      availabilityTypes,
      selectedOffice,
    } = props;
    const eventId = timeOffRequestToEdit.eventId;
    const [timeOffRequest, setTimeOffRequest] = useState({
      ...timeOffRequestToEdit,
      ...{
        startDate: timeOffRequestToEdit.start,
        endDate: timeOffRequestToEdit.end,
        startTime: timeOffRequestToEdit.isAllDay
          ? undefined
          : format(timeOffRequestToEdit.start, "HH:mm"),
        endTime: timeOffRequestToEdit.isAllDay
          ? undefined
          : format(timeOffRequestToEdit.end, "HH:mm"),
      },
    });

    const [showDenialReason, setShowDenialReason] = useState(false);
    const [denialReason, setDenialReason] = useState("");
    const [error, setError] = useState("");
    const [startError, setStartError] = useState("");
    const [endError, setEndError] = useState("");
    let allDateTimeOffs;
    let userSoft: any;
    let timeOffEventsNotCurrentRequest;
    let showCancel;
    let showManagerActions;
    let allowEdits = false;
    if (ref && "current" in ref && ref.current) {
      showCancel =
        user.id == timeOffRequestToEdit.employee.id &&
        timeOffRequestToEdit.status === "REQUESTED";
      showManagerActions =
        (user.isManager || user.isAdmin) &&
        timeOffRequest.status === "REQUESTED";
      allDateTimeOffs =
        ref.current.eventsData &&
        ref.current.eventsData.length > 0 &&
        ref.current.eventsData.filter(
          (event) => event.eventType === "EMPLOYEETIMEOFF",
        );

      /**
       * TODO: Broken, eventType should be OFFPREFERENCE, and should be checking user.id not userSoft.id
       * Also user.id is a string, event.employee.id is a number
       * Should be going in ref.current.eventsData.
       */

      userSoft =
        ref.current.eventsData &&
        ref.current.eventsData.length > 0 &&
        ref.current.eventsData.filter(
          (event) =>
            event.eventType === "OFFPREFERENCES" &&
            event.employee.id === userSoft.id,
        );

      timeOffEventsNotCurrentRequest =
        allDateTimeOffs &&
        allDateTimeOffs.length > 0 &&
        allDateTimeOffs.filter((event: any) => event.eventId !== eventId);
    } else {
      showCancel =
        user.id == timeOffRequestToEdit.employeeIds[0] &&
        timeOffRequestToEdit.status === "REQUESTED";
      showManagerActions = false;
    }

    interface OffPreference {
      Guid: string;
      description: string;
      employee: {
        id: string;
      };
      employeeResource: number;
      end: Date;
      eventId: string;
      eventType: string;
      highPriority: boolean;
      id: string;
      isAllDay: boolean;
      officeResource: String;
      shiftResource: string;
      start: Date;
      subject: string;
    }

    let invalidDates: any = [];
    userSoft &&
      userSoft.length > 0 &&
      userSoft.forEach((request: OffPreference) => {
        invalidDates.push(request.start);
      });

    timeOffEventsNotCurrentRequest &&
      timeOffEventsNotCurrentRequest.length > 0 &&
      timeOffEventsNotCurrentRequest.forEach((request) => {
        const arrayOfTimeOff = eachDayOfInterval({
          start: request.start,
          end: request.end,
        });
        invalidDates = [...invalidDates, ...arrayOfTimeOff];
      });

    invalidDates = invalidDates.map((date: Date) => format(date, "MM/dd/yyyy"));

    // leave in for now. brining back update function later
    // const [updateRequest] = useMutation(UPDATE_EMPLOYEE_AVAILABILITY, {
    //   onCompleted(d) {
    //     if (ref) {
    //       updateUnavailabilityEvent(
    //         ref.current,
    //         d.updateEmployeeAvailability.employeeAvailability,
    //         timeOffRequestToEdit,
    //         selectedOffice.name,
    //       );
    //     }
    //     toastShow("Edit Time Off Request successfully updated", "Success");
    //     closeDialog();
    //   },
    //   onError(error) {
    //     console.log(error);
    //     toastShow(
    //       "Unable to edit time off request. Please check dates and try again.",
    //       "Error",
    //     );
    //     notifyDevelopers({
    //       variables: {
    //         message:
    //           "Error on UPDATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " +
    //           environment +
    //           ". Graphql " +
    //           error,
    //       },
    //     });
    //   },
    // });

    const [deleteRequest] = useMutation(CANCEL_EMPLOYEE_TIME_OFF, {
      update(cache, { data: { cancelEmployeeTimeOff } }) {
        if (cancelEmployeeTimeOff.result.canceledTimeOff) {
          cache.evict({
            id: `EmployeeTimeOffNode:${cancelEmployeeTimeOff.result.canceledTimeOff.id}`,
          });
        }
      },
      onCompleted(d) {
        if (
          d.cancelEmployeeTimeOff.result.__typename ===
          "CancelEmployeeTimeOffResult"
        ) {
          if (ref && "current" in ref && ref.current) {
            updateTimeOffEvent(
              ref.current,
              d.cancelEmployeeTimeOff.result.canceledTimeOff,
              timeOffRequestToEdit,
              selectedOffice.name,
            );
          }
          toastShow("Edit Time Off Request successfully deleted", "Success");
          closeDialog();
        } else if (
          d.cancelEmployeeTimeOff.result.__typename === "StaleTimeOffError"
        ) {
          if (ref && "current" in ref && ref.current) {
            updateTimeOffEvent(
              ref.current,
              d.cancelEmployeeTimeOff.result.currentTimeOff,
              timeOffRequestToEdit,
              selectedOffice.name,
            );
          }
          const currentTor = transformTimeOffv2([
            d.cancelEmployeeTimeOff.result.currentTimeOff,
          ])[0];
          setTimeOffRequest({
            ...currentTor,
            ...{ startDate: currentTor.start, endDate: currentTor.end },
          });
          toastShow(d.cancelEmployeeTimeOff.result.message, "Error");
        } else {
          toastShow(d.cancelEmployeeTimeOff.result.message, "Error");
          notifyDevelopers({
            variables: {
              message:
                "Error on CANCEL_EMPLOYEE_TIME_OFF Mutation. Environment: " +
                environment +
                ". Graphql " +
                error,
            },
          });
        }
      },
      onError(error) {
        console.log(error);
        toastShow(
          "Unable to delete time off request. Please try again.",
          "Error",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on CANCEL_EMPLOYEE_TIME_OFF Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

    //TODO: implement when update timeOffRequest feature exists
    // type Event = {
    //   type: string;
    //   workHours: number;
    //   firstday: string;
    //   lastday: string;
    //   comment: string;
    //   startTime: string | undefined;
    //   endTime: string | undefined;
    // };
    // const handleSubmit = () => {
    //   let newEvent: Event = {
    //     type: type,
    //     workHours: workHours,
    //     firstday: format(startDate, "yyyy-MM-dd"),
    //     lastday: format(endDate, "yyyy-MM-dd"),
    //     comment: comment,
    //     startTime: undefined,
    //     endTime: undefined,
    //   };
    //   if (isAllDay === false && startTime && endTime) {
    //     newEvent.startTime = `${startTime}:00`;
    //     newEvent.endTime = `${endTime}:00`;
    //   }
    //   updateRequest({
    //     variables: {
    //       id: eventId,
    //       input: {
    //         ...newEvent,
    //       },
    //     },
    //   });
    // };

    const handleDeleteRequest = () => {
      deleteRequest({
        variables: {
          id: parseInt(timeOffRequest.eventId),
          version: parseInt(timeOffRequest.version),
        },
      });
    };

    const [approveRequest] = useMutation(MANAGER_APPROVE_EMPLOYEE_TIME_OFF, {
      update(cache, { data: { approveTimeOffRequest } }) {
        if (
          approveTimeOffRequest.result.__typename ===
          "ManagerApproveEmployeeTimeOffResult"
        ) {
          const newSlacks = getSlackTransformer(
            -1,
            -1,
          )(approveTimeOffRequest.result.slacks);

          if (
            ref &&
            "current" in ref &&
            ref.current &&
            "slackEvents" in ref.current
          ) {
            ref.current.slackEvents = {
              ...ref.current.slackEvents,
              ...newSlacks,
            };
          }

          const paginator = new DateTimePaginator(1);
          approveTimeOffRequest.result.slacks.forEach(
            (element: SlackElement) => {
              const start = new Date(element.interval.start);
              const end = new Date(element.interval.end);
              const dates = paginator.getQueryDateRanges(start, end);
              dates.forEach((date: [Date, Date]) => {
                const variables = {
                  issuesOnly: true,
                  office: selectedOffice.id,
                  pageEnd: date[1].toISOString(),
                  pageStart: date[0].toISOString(),
                };
                const strVariables = JSON.stringify(variables);
                cache.modify({
                  fields: {
                    intervalSlacks: (existing, { storeFieldName }) => {
                      /**
                       * Modifier function to update the cached query `intervalSlacks`.
                       *
                       * Queries may be in the cache multiple times if they were called with
                       * different arguments. This callback function will be called on each of
                       * those cached fields so we check whether the variables we are interested
                       * in is contained in the `storeFieldName` which is the full key that includes
                       * the serialized variables. N.B. checking if a string includes a substring
                       * means that the order of the variables and the type (int vs string) matter.
                       * https://www.apollographql.com/docs/react/caching/cache-interaction#examples
                       * https://www.apollographql.com/docs/react/api/cache/InMemoryCache#modify
                       *
                       * @param {SlackNode[]} existing the array of object currently in the cache.
                       * @param {String} storeFieldName the serialized full key of the field including variable arguments
                       *
                       * @returns {SlackNode[]} the new object appended to existing or the existing array.
                       */
                      if (storeFieldName.includes(strVariables)) {
                        const newSlackRef = cache.writeFragment({
                          data: element,
                          fragment: SLACK_FRAGMENT,
                        });
                        return [...existing, newSlackRef];
                      } else {
                        return existing;
                      }
                    },
                  },
                });
              });
            },
          );

          approveTimeOffRequest.result.deletedShiftAssignments.forEach(
            (sa: any) => {
              cache.evict({ id: `EfficientShiftAssignmentNode:${sa}` });
            },
          );
        }
      },
      onCompleted(data) {
        if (
          data.approveTimeOffRequest.result.__typename ===
          "ManagerApproveEmployeeTimeOffResult"
        ) {
          if (ref && "current" in ref) {
            updateTimeOffEvent(
              ref.current,
              data.approveTimeOffRequest.result.approvedTimeOff,
              timeOffRequest,
              selectedOffice.name,
            );
            updateScheduleFromOption(
              ref.current,
              data.approveTimeOffRequest.result.impactedShifts,
              selectedOffice.name,
            );
          }
          closeDialog();
          toastShow("Manager Approve Time Off Request Success", "Success");
        } else if (
          data.approveTimeOffRequest.result.__typename === "StaleTimeOffError"
        ) {
          if (ref && "current" in ref && ref.current) {
            updateTimeOffEvent(
              ref.current,
              data.approveTimeOffRequest.result.currentTimeOff,
              timeOffRequest,
              selectedOffice.name,
            );
          }
          const untransformedTor =
            data.approveTimeOffRequest.result.currentTimeOff;

          if (untransformedTor.status === "REQUESTED") {
            const currentTor = transformTimeOffv2([untransformedTor])[0];
            setTimeOffRequest({
              ...currentTor,
              ...{ startDate: currentTor.start, endDate: currentTor.end },
            });
            toastShow(data.approveTimeOffRequest.result.message, "Error");
          } else if (untransformedTor.status === "CANCELED") {
            toastShow(
              "The user that requested this time off has canceled it.",
              "Error",
            );
            closeDialog();
          } else if (untransformedTor.status === "APPROVED") {
            const currentTor = transformTimeOffv2([untransformedTor])[0];
            setTimeOffRequest({
              ...currentTor,
              ...{ startDate: currentTor.start, endDate: currentTor.end },
            });
            toastShow(
              `${untransformedTor.resolvedBy.lastName}, ${untransformedTor.resolvedBy.firstName} has already approved this time off.`,
              "Error",
            );
          } else if (untransformedTor.status === "DENIED") {
            toastShow(
              `${untransformedTor.resolvedBy.lastName}, ${untransformedTor.resolvedBy.firstName} has already denied this time off.`,
              "Error",
            );
            closeDialog();
          }
        } else {
          toastShow(data.approveTimeOffRequest.result.message, "Error");
        }
      },
      onError(error) {
        console.error(error);
        toastShow("Error Approving Time Off.", "Error");
        notifyDevelopers({
          variables: {
            message:
              "Error on MANAGER_APPROVE_EMPLOYEE_TIME_OFF Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

    const handleApproveRequest = (args: any) => {
      approveRequest({
        variables: {
          id: args.id,
          version: args.version,
          approvingManager: parseInt(user.id),
        },
      });
    };

    const [denyRequest] = useMutation(MANAGER_DENY_EMPLOYEE_TIME_OFF, {
      update(cache, { data: { denyTimeOffRequest } }) {
        if (denyTimeOffRequest.result.deniedTimeOff) {
          cache.evict({
            id: `EmployeeTimeOffNode:${denyTimeOffRequest.result.deniedTimeOff.id}`,
          });
        }
      },
      onCompleted(data) {
        if (
          data.denyTimeOffRequest.result.__typename ===
          "ManagerDenyEmployeeTimeOffResult"
        ) {
          if (ref && "current" in ref) {
            updateTimeOffEvent(
              ref.current,
              data.denyTimeOffRequest.result.deniedTimeOff,
              timeOffRequest,
              selectedOffice.name,
            );
          }
          closeDialog();
          toastShow("Manager Deny Time Off Request Success", "Success");
        } else if (
          data.denyTimeOffRequest.result.__typename === "StaleTimeOffError"
        ) {
          if (ref && "current" in ref && ref.current) {
            updateTimeOffEvent(
              ref.current,
              data.denyTimeOffRequest.result.currentTimeOff,
              timeOffRequest,
              selectedOffice.name,
            );
          }
          const untransformedTor =
            data.denyTimeOffRequest.result.currentTimeOff;

          if (untransformedTor.status === "REQUESTED") {
            const currentTor = transformTimeOffv2([untransformedTor])[0];
            setTimeOffRequest({
              ...currentTor,
              ...{ startDate: currentTor.start, endDate: currentTor.end },
            });
            toastShow(data.denyTimeOffRequest.result.message, "Error");
          } else if (untransformedTor.status === "CANCELED") {
            toastShow(
              "The user that requested this time off has canceled it.",
              "Error",
            );
            closeDialog();
          } else if (untransformedTor.status === "APPROVED") {
            const currentTor = transformTimeOffv2([untransformedTor])[0];
            setTimeOffRequest({
              ...currentTor,
              ...{ startDate: currentTor.start, endDate: currentTor.end },
            });
            toastShow(
              `${untransformedTor.resolvedBy.lastName}, ${untransformedTor.resolvedBy.firstName} has already approved this time off.`,
              "Error",
            );
          } else if (untransformedTor.status === "DENIED") {
            toastShow(
              `${untransformedTor.resolvedBy.lastName}, ${untransformedTor.resolvedBy.firstName} has already denied this time off.`,
              "Error",
            );
            closeDialog();
          }
        } else {
          toastShow(data.denyTimeOffRequest.result.message, "Error");
        }
      },
      onError(error) {
        console.log(error);
        toastShow("Error Denying Time Off.", "Error");
        notifyDevelopers({
          variables: {
            message:
              "Error on MANAGER_DENY_EMPLOYEE_TIME_OFF Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

    const handleDenyRequest = (args: any) => {
      denyRequest({
        variables: {
          id: args.id,
          version: args.version,
          denyingManager: parseInt(user.id),
          deniedText: denialReason,
        },
      });
    };

    const checkInvalid = (date: Date) => {
      const formatted = format(date, "MM/dd/yyyy");
      return invalidDates.includes(formatted);
    };

    const eligibleToSave = Boolean(
      !checkInvalid(timeOffRequest.startDate) &&
        !checkInvalid(timeOffRequest.endDate) &&
        !timeOffRequest.endDate < timeOffRequest.startDate,
    );

    const handleUpdateStart = (date: Date | null) => {
      if (date && !isNaN(date.getTime())) {
        const invalid = checkInvalid(date);
        if (!invalid) {
          if (date > timeOffRequest.endDate) {
            setTimeOffRequest({
              ...timeOffRequest,
              ...{ startDate: date, endDate: date },
            });
          } else {
            setTimeOffRequest({ ...timeOffRequest, ...{ startDate: date } });
          }
          setStartError("");
        } else {
          setStartError(
            "You must choose a date that does not already have a request",
          );
        }
      }
    };

    const handleUpdateEnd = (date: Date | null) => {
      if (date && !isNaN(date.getTime())) {
        const invalid = checkInvalid(date);
        if (!invalid) {
          setTimeOffRequest({ ...timeOffRequest, ...{ endDate: date } });
          setEndError("");
        } else {
          setEndError(
            "You must choose a date that does not already have a request",
          );
        }
      }
    };

    const handleWorkHoursChange = (event: any) => {
      setTimeOffRequest({
        ...timeOffRequest,
        ...{ workHours: event.target.value },
      });
    };

    const handleIsAllDayChange = (e: any) => {
      setTimeOffRequest({
        ...timeOffRequest,
        ...{ isAllDay: e.target.checked },
      });
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container direction="column" spacing={2}>
          <Grid item container justifyContent="space-between">
            <Grid item>
              {allowEdits ? (
                <Typography variant="h3">Edit Time Off Request</Typography>
              ) : (
                <Typography variant="h3">Time Off Request</Typography>
              )}
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                color="secondary"
                size="small"
                onClick={closeDialog}
                data-testid="closeEditTimeOffFormShift"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>
            <FormControlLabel
              data-testid="allDaySwitchEditTimeOffFormShift"
              control={
                <Switch
                  checked={timeOffRequest.isAllDay}
                  onChange={handleIsAllDayChange}
                  name="isAllDay"
                  color={timeOffRequest.isAllDay ? "primary" : "secondary"}
                  disabled={!allowEdits}
                />
              }
              label="All Day"
            />
          </Grid>
          <Grid item container justifyContent="flex-start" spacing={2}>
            <Grid item>
              <InputLabel htmlFor="start-date">
                <Typography variant="h6">Start Date:</Typography>
              </InputLabel>
              <DatePicker
                inputFormat="MM/dd/yyyy"
                shouldDisableDate={checkInvalid}
                value={timeOffRequest.startDate}
                onChange={handleUpdateStart}
                className={classes.input}
                renderInput={(props) => (
                  <TextField variant="outlined" id="start-date" {...props} />
                )}
                data-testid="startDateEditTimeOffFormShift"
                disabled={!allowEdits}
              />
              {startError && (
                <Typography variant="body2" className={classes.dateError}>
                  {startError}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <InputLabel htmlFor="end-date">
                <Typography variant="h6">End Date:</Typography>
              </InputLabel>
              <DatePicker
                inputFormat="MM/dd/yyyy"
                shouldDisableDate={checkInvalid}
                minDate={timeOffRequest.startDate}
                value={timeOffRequest.endDate}
                onChange={handleUpdateEnd}
                className={classes.input}
                disabled={!allowEdits}
                renderInput={(props) => {
                  return (
                    <TextField {...props} variant="outlined" id="end-date" />
                  );
                }}
                data-testid="endDateEditTimeOffFormShift"
              />
              {endError && (
                <Typography variant="body2" className={classes.dateError}>
                  {endError}
                </Typography>
              )}
            </Grid>
          </Grid>
          {timeOffRequest.isAllDay === false && (
            <Grid item container spacing={1} alignItems="center">
              <Grid item>
                <InputLabel htmlFor="start-time">
                  <Typography variant="h6">Start Time:</Typography>
                </InputLabel>
                <TextField
                  id={"start-time"}
                  name={"startTime"}
                  variant="outlined"
                  value={timeOffRequest.startTime}
                  onChange={(e) =>
                    setTimeOffRequest({
                      ...timeOffRequest,
                      ...{
                        startTime: e.target.value,
                      },
                    })
                  }
                  type="time"
                  disabled={timeOffRequest.isAllDay || !allowEdits}
                  className={classes.timeField}
                  data-testid="startTimeEditTimeOffFormShift"
                />
              </Grid>
              <Grid item>
                <InputLabel htmlFor="end-time">
                  <Typography variant="h6">End Time:</Typography>
                </InputLabel>
                <TextField
                  id={"end-time"}
                  name={"endTime"}
                  variant="outlined"
                  value={timeOffRequest.endTime}
                  onChange={(e) =>
                    setTimeOffRequest({
                      ...timeOffRequest,
                      ...{
                        endTime: e.target.value,
                      },
                    })
                  }
                  type="time"
                  disabled={timeOffRequest.isAllDay || !allowEdits}
                  className={classes.timeField}
                  data-testid="endTimeEditTimeOffFormShift"
                />
              </Grid>
            </Grid>
          )}
          <Grid item container spacing={1} alignItems="center">
            <Grid item>
              <InputLabel htmlFor="type">
                <Typography variant="h6">Type of Request:</Typography>
              </InputLabel>
            </Grid>
            <Grid item>
              <Select
                id="type"
                name="type"
                variant="outlined"
                type="number"
                value={
                  timeOffRequest.type.id
                    ? timeOffRequest.type.id
                    : timeOffRequest.typeId
                }
                className={classes.input}
                onChange={(e) =>
                  setTimeOffRequest({
                    ...timeOffRequest,
                    ...{
                      typeId: e.target.value,
                    },
                  })
                }
                data-testid="requestTypeEditTimeOffFormShift"
                disabled={!allowEdits}
              >
                {availabilityTypes && availabilityTypes.length > 0
                  ? availabilityTypes.map((type, index) => (
                      <MenuItem key={index} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Grid>
          </Grid>
          <Grid item container spacing={1} alignItems="center">
            <Grid item>
              <InputLabel htmlFor="work-hours">
                <Typography variant="h6">Hours Used:</Typography>
              </InputLabel>
            </Grid>
            <Grid item>
              <TextField
                id="work-hours"
                name="work-hours"
                variant="outlined"
                type="number"
                value={timeOffRequest.workHours}
                onChange={handleWorkHoursChange}
                data-testid="workhoursEditTimeOffFormShift"
                disabled={!allowEdits}
              />
            </Grid>
          </Grid>
          <Grid item>
            <InputLabel htmlFor="comment">
              <Typography variant="h6">Comment:</Typography>
            </InputLabel>
            <TextField
              id="comment"
              name="comment"
              variant="outlined"
              value={timeOffRequest.comment}
              className={classes.wideInput}
              onChange={(e) =>
                setTimeOffRequest({
                  ...timeOffRequest,
                  ...{
                    comment: e.target.value,
                  },
                })
              }
              data-testid="commentEditTimeOffFormShift"
              disabled={!allowEdits}
            />
          </Grid>
          <Grid item>
            {error && (
              <Typography className={classes.error}>{error}</Typography>
            )}
          </Grid>
          {showCancel && (
            <Grid item container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={handleDeleteRequest}
                  data-testid="delete-time-off-button"
                >
                  Delete
                </Button>
              </Grid>
              {/*leave in for future update feature*/}
              {/* <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmit}
                disabled={!eligibleToSave || workHours === 0}
                data-testid="saveTimeOffFormShift"
              >
                Save
              </Button>
            </Grid> */}
            </Grid>
          )}
          {showManagerActions && (
            <Grid item container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="success"
                  className={classes.button}
                  onClick={() =>
                    handleApproveRequest({
                      id: timeOffRequest.eventId,
                      version: timeOffRequest.version,
                    })
                  }
                  data-testid="approve-time-off-button"
                >
                  Approve
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="error"
                  className={classes.button}
                  onClick={() => setShowDenialReason(true)}
                  data-testid="deny-time-off-button"
                >
                  Deny
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Dialog open={showDenialReason} fullWidth maxWidth="xs">
          <DialogContent style={{ padding: 30 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h3">Reason for Denial</Typography>
              </Grid>
              <Grid item justifyContent="flex-end">
                <TextField
                  variant="outlined"
                  value={denialReason}
                  onChange={(e) => setDenialReason(e.target.value)}
                  className={classes.denialInput}
                  onKeyPress={(e) => {
                    e.key === "Enter" &&
                      handleDenyRequest({
                        id: timeOffRequest.eventId,
                        version: timeOffRequest.version,
                      });
                  }}
                />
              </Grid>
              <Grid item container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      handleDenyRequest({
                        id: timeOffRequest.eventId,
                        version: timeOffRequest.version,
                      });
                      setShowDenialReason(false);
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      setShowDenialReason(false);
                      setDenialReason("");
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </LocalizationProvider>
    );
  },
);

export default EditTimeOffRequestForm;
