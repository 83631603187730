import React from "react";
import { Grid, Link, Typography } from "@mui/material";

const ErrorMessage = () => {
  return (
    <Grid container>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography>An error has occurred!</Typography>
          <Typography>
            Please try again or consider refreshing the page.
          </Typography>
          <Typography>
            {" "}
            If the error persists, please visit our{" "}
            <Link
              href="https://dualitysys.atlassian.net/servicedesk/customer/portal/1"
              target="_blank"
              rel="noopener"
            >
              support portal
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorMessage;
