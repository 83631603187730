import React, { useState } from "react";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DateFnsUtils from "@date-io/date-fns";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import { AVAILABILITY_TYPES, NOTIFY_DEVELOPERS } from "../../api/gqlQueries";
import { useMutation, gql, useQuery } from "@apollo/client";
import { selectedDateVar, userVar } from "../../cache";
import Roles from "../../Roles/roles";
import { formatTimeOff } from "../../helpers/formatMissionEvents";
import EmployeeSearchBox from "../general/EmployeeSearchBox";

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 138,
    maxWidth: 225,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  employeeInput: {
    width: 225,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wideInput: {
    width: 575,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    width: 50,
  },
  timeField: {
    width: 120,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.primary.main,
  },
  helperText: {
    color: theme.palette.text.secondary,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    marginTop: -4,
    paddingLeft: 14,
    paddingRight: 14,
  },
}));

const gqlFragment = gql`
  fragment NewEmployeeAvailability on EmployeeAvailabilityNode {
    id
    firstday
    lastday
    startTime
    endTime
    employee {
      id
      firstName
      lastName
    }
    approvedby {
      id
      firstName
      lastName
    }
    type {
      name
      id
    }
    office {
      id
    }
    workHours
    comment
    deniedText
    userCancelled
  }
`;

const TimeOffRequestForm = (props) => {
  const classes = useStyles();

  const {
    closeDialog,
    invalidDates,
    setToast,
    setShowToast,
    employees,
    refetch,
    setOpenSnackBar,
    setSnackBarMessage,
    environment,
    allDateTimeOffs,
    SetAllDateTimeOffs,
  } = props;

  const user = userVar();
  const date = selectedDateVar();

  employees.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const managerAccess =
    user.role === Roles.MANAGER ||
    user.role === Roles.SCHEDULER ||
    user.role === Roles.ADMIN;

  const [employee, setEmployee] = useState(user.id);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [isAllDay, setIsAllDay] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [workHours, setWorkHours] = useState(0);
  const [type, setType] = useState("1");
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const [endError, setEndError] = useState("");
  const [startError, setStartError] = useState("");

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const { data } = useQuery(AVAILABILITY_TYPES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.",
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on AVAILABILITY_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleSubmit = () => {
    let newEvent = {
      office: parseInt(user.office.id),
      type: type,
      workHours: parseInt(workHours),
      firstday: format(startDate, "yyyy-MM-dd"),
      lastday: format(endDate, "yyyy-MM-dd"),
      comment: comment,
    };
    if (startTime && endTime) {
      newEvent.startTime = `${startTime}:00`;
      newEvent.endTime = `${endTime}:00`;
    }

    if (managerAccess) {
      if (employee === "All") {
        console.log("hi");
        let timeOffInput = [];
        employees.forEach((employee) => {
          if (employee.id) {
            const eventWithEmployee = { ...newEvent };
            eventWithEmployee.employee = parseInt(employee.id);
            console.log(eventWithEmployee);
            timeOffInput.push(eventWithEmployee);
          }
        });
      } else {
        newEvent.employee = parseInt(employee);
      }
    } else {
      newEvent.employee = parseInt(user.id);
    }
  };

  const checkInvalid = (date) => {
    if (employee !== user.id) {
      return false;
    } else {
      const formatted = format(date, "MM/dd/yyyy");
      return invalidDates.includes(formatted);
    }
  };

  const eligibleToSave = Boolean(
    !checkInvalid(startDate) && !checkInvalid(endDate) && !endDate < startDate,
  );

  const handleUpdateStart = (date) => {
    if (date && !isNaN(date.getTime())) {
      const invalid = checkInvalid(date);
      if (!invalid || employee !== user.id) {
        setStartDate(date);
        setStartError("");
        if (date > endDate) {
          setEndDate(date);
        }
      } else {
        setStartError(
          "You must choose a date that does not already have a request",
        );
      }
    }
  };

  const handleUpdateEnd = (date) => {
    if (date && !isNaN(date.getTime())) {
      const invalid = checkInvalid(date);
      if (!invalid || employee !== user.id) {
        setEndDate(date);
        setEndError("");
      } else {
        setEndError(
          "You must choose a date that does not already have a request",
        );
      }
    }
  };

  const handleWorkHoursChange = (event) => {
    setWorkHours(event.target.value);
  };

  let sortedTypes;
  if (data) {
    sortedTypes = [];
    const ordered = ["PTO", "UPTO", "TRAINING", "Medical", "Admin", "OTHER"];
    ordered.forEach((name) => {
      let match = data.availabilityTypes.find((type) => type.name === name);
      if (match) {
        match = { name: match.name, id: match.id };
        if (match.name === "TRAINING" || match.name === "OTHER") {
          match.name =
            match.name.slice(0, 1) + match.name.slice(1).toLowerCase();
          sortedTypes.push(match);
        } else {
          sortedTypes.push(match);
        }
      }
    });
  }

  const handleIsAllDayChange = (e) => {
    setIsAllDay(e.target.checked);
    !e.target.checked && setEndDate(startDate);
    e.target.checked && setStartTime("");
    e.target.checked && setEndTime("");
  };

  console.log(employees);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h3">Add Calendar Event</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeDialog}
              data-testid="timeOffFormClose"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={isAllDay}
                onChange={handleIsAllDayChange}
                name="isAllDay"
                color={isAllDay ? "primary" : "secondary"}
                data-testid="timeOffEventAllDayFlag"
              />
            }
            label="All Day"
          />
        </Grid>
        {managerAccess && (
          <Grid item>
            <Typography variant="h6">Employee:</Typography>
            {/* <EmployeeSearchBox
              options={employees}
              name="employeesToSelect"
              id="employeesToSelect"
              placeholder="Filter by Employees"
              setEmployee={setEmployee}
              val={employee}
            /> */}
            <Select
              id="employee"
              name="employee"
              variant="outlined"
              value={employee}
              className={classes.employeeInput}
              onChange={(e) => setEmployee(e.target.value)}
              data-testid="timeOffEventEmployee"
            >
              <MenuItem value="All">All Employees</MenuItem>
              {employees
                ? employees.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      {employee.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </Grid>
        )}
        <Grid item container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <InputLabel htmlFor="start-date">
              <Typography variant="h6">Start Date:</Typography>
            </InputLabel>
            <div data-testid="timeOffEventStartDate">
              <DatePicker
                disableToolbar
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                id="start-date"
                shouldDisableDate={checkInvalid}
                value={startDate}
                onChange={handleUpdateStart}
                className={classes.input}
                renderInput={(props) => <TextField {...props} />}
              />
            </div>
            {startError && (
              <Typography variant="body2" className={classes.dateError}>
                {startError}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <InputLabel htmlFor="end-date">
              <Typography variant="h6">End Date:</Typography>
            </InputLabel>
            <div data-testid="timeOffEventEndDate">
              <DatePicker
                disableToolbar
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                id="end-date"
                shouldDisableDate={checkInvalid}
                minDate={startDate}
                minDateMessage="Date should not be before start date"
                value={endDate}
                onChange={handleUpdateEnd}
                className={classes.input}
                disabled={!isAllDay}
                renderInput={(props) => <TextField {...props} />}
              />
            </div>
            {endError && (
              <Typography variant="body2" className={classes.dateError}>
                {endError}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <InputLabel htmlFor="start-time">
              <Typography variant="h6">Start Time:</Typography>
            </InputLabel>
            <TextField
              id={"start-time"}
              name={"startTime"}
              variant="outlined"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              type="time"
              disabled={isAllDay}
              className={classes.timeField}
              data-testid="timeOffEventStartTime"
            />
          </Grid>
          <Grid item>
            <InputLabel htmlFor="end-time">
              <Typography variant="h6">End Time:</Typography>
            </InputLabel>
            <TextField
              id={"end-time"}
              name={"endTime"}
              variant="outlined"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              type="time"
              disabled={isAllDay}
              className={classes.timeField}
              data-testid="timeOffEventEndTime"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <InputLabel htmlFor="type">
              <Typography variant="h6">Type of Request:</Typography>
            </InputLabel>
          </Grid>
          <Grid item>
            <Select
              id="type"
              name="type"
              variant="outlined"
              type="number"
              value={type}
              onChange={(e) => setType(e.target.value)}
              data-testid="timeOffEventRequestType"
            >
              {sortedTypes
                ? sortedTypes.map((type) => (
                    <MenuItem key={type.name + type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </Grid>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <InputLabel htmlFor="work-hours">
              <Typography variant="h6">Hours Used:</Typography>
            </InputLabel>
          </Grid>
          <Grid item>
            <TextField
              id="work-hours"
              name="work-hours"
              variant="outlined"
              type="number"
              value={workHours}
              onChange={handleWorkHoursChange}
              data-testid="timeOffEventWorkHours"
            />
          </Grid>
        </Grid>
        <Grid item>
          <InputLabel htmlFor="comment">
            <Typography variant="h6">Comment:</Typography>
          </InputLabel>
          <TextField
            id="comment"
            name="comment"
            variant="outlined"
            value={comment}
            className={classes.wideInput}
            onChange={(e) => setComment(e.target.value)}
            data-testid="timeOffEventComment"
          />
        </Grid>
        <Grid item>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={employee === user.id ? !eligibleToSave : false}
              data-testid="timeOffEventSubmit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default TimeOffRequestForm;
