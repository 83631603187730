interface EnvVariables {
  hourCycle: String;
}

const DATE_FORMATTER: Intl.DateTimeFormat = new Intl.DateTimeFormat("en", {
  year: "2-digit",
  month: "numeric",
  day: "numeric",
});

const TIME_FORMATTER: Intl.DateTimeFormat = new Intl.DateTimeFormat("en", {
  hour: "numeric",
  minute: "numeric",
});

const TIME_24HR_FORMATTER: Intl.DateTimeFormat = new Intl.DateTimeFormat("en", {
  hour: "numeric",
  minute: "numeric",
  hourCycle: "h23",
});

const DATETIME_FORMATTER: Intl.DateTimeFormat = new Intl.DateTimeFormat("en", {
  year: "2-digit",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});

const DATETIME_24HR_FORMATTER: Intl.DateTimeFormat = new Intl.DateTimeFormat(
  "en",
  {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hourCycle: "h23",
  },
);

const DATE_RANGE_FORMATTER: Intl.DateTimeFormat = new Intl.DateTimeFormat(
  "en",
  {
    month: "short",
    day: "numeric",
  },
);

/**
 * Formats `date` based on the locale and any environment variables; default is 12/25/24
 * @param {Date} date date to be formatted
 * @param {EnvVariables} env object tracking environment variables for the client
 */
export function formatDate(date: Date, env: EnvVariables): String {
  return DATE_FORMATTER.format(date);
}

/**
 * Formats `date` based on the locale and `env` variables set for the client; default is
 * HH:MM AM/PM
 * @param date date to be formatted
 * @param env object tracking environment variables for the client
 */
export function formatTime(date: Date, env: EnvVariables): String {
  if (env.hourCycle === "h23") {
    return TIME_24HR_FORMATTER.format(date);
  } else {
    return TIME_FORMATTER.format(date);
  }
}

/**
 * Formats `date` based on the locale and `env` variables set for the client; default is
 * m/d/yy, HH:MM AM/PM.
 * @param date date to be formatted
 * @param env object tracking environment variables for the client
 * @returns string representation of the datetime
 */
export function formatDateTime(date: Date, env: EnvVariables): String {
  if (env.hourCycle === "h23") {
    return DATETIME_24HR_FORMATTER.format(date);
  } else {
    return DATETIME_FORMATTER.format(date);
  }
}

/**
 * Formats the date range between `start` and `end` according to the locale and `env` variables;
 * default is 12/1/24, 12:00 - 5:00 PM
 *
 * The two parts are separated by a narrow space (\u2009), then an n-dash (\u2013), then another narrow space.
 * It may also utilize the narrow no-break space as well (\u202F).
 *
 * @param start start of the date range
 * @param end end of the date range
 * @param env object tracking environment variables for the client
 * @returns string representation of the date range
 */
export function formatRange(start: Date, end: Date, env: EnvVariables): String {
  if (
    !(
      start.getHours() ||
      start.getMinutes() ||
      end.getHours() ||
      end.getMinutes()
    )
  ) {
    // midnight on both dates
    const newEnd = new Date(end).setDate(end.getDate() - 1);
    const out = DATE_RANGE_FORMATTER.formatRange(start, newEnd);
    if (out.length <= 6) {
      return `${out} (all day)`;
    }
    return out;
  }
  if (env.hourCycle === "h23") {
    return DATETIME_24HR_FORMATTER.formatRange(start, end);
  } else {
    return DATETIME_FORMATTER.formatRange(start, end);
  }
}
