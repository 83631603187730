import React, { forwardRef, ForwardedRef } from "react";
import { Typography, Grid, IconButton } from "@mui/material";
import { ScheduleComponent } from "@syncfusion/ej2-react-schedule";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import {
  CREATE_EMPLOYEE_TIME_OFF,
  NOTIFY_DEVELOPERS,
  SLACK_FRAGMENT,
  EMPLOYEE_TIME_OFF_FRAGMENT,
  MANAGER_CREATE_EMPLOYEE_TIME_OFF,
} from "../../api/gqlQueries";
import { useMutation } from "@apollo/client";
import { userVar } from "../../cache";
import Roles from "../../Roles/roles";
import { updateScheduleFromOption, updateTimeOffEvent } from "./UpdateEvents";
import { DateTimePaginator } from "../../helpers/DateTimePaginator";
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { getSlackTransformer } from "./Transformer";
import { SelectedOffice, User } from "../../types";
import TimeOffInputs from "./TimeOffInputs";
import { toIsoDate } from "../../helpers/formatTime";
let toastObj;

function toastShow(content: string, type: string) {
  toastObj = ToastUtility.show({
    content: content,
    icon:
      type === "Warning"
        ? "e-warning toast-icons"
        : type === "Success"
          ? "e-success toast-icons"
          : "e-error toast-icons",
    timeOut: 3000,
    position: { X: "Center", Y: "Top" },
    showCloseButton: true,
    cssClass:
      type === "Warning"
        ? "e-toast-warning"
        : type === "Success"
          ? "e-toast-success"
          : "e-toast-danger",
  });
}

/* Add time off on a given date
   start date and end date can span over a few days.
   user can select a part of the day or user can set the event to an all day event.
   user can delete their time off requests
*/

interface TimeOffRequestProps {
  closeDialog: () => void;
  invalidDates: string[];
  employees: any[];
  environment: string;
  selectedOffice: SelectedOffice;
}

interface Office {
  id: string;
  name: string;
}

interface ShiftNode {
  id: string;
  start: string;
  end: string;
  procedure: string;
  office: Office;
}

interface IntervalNode {
  id: string;
  start: string;
  end: string;
  shifts: ShiftNode[];
}

interface SkillNode {
  id: string;
  name: string;
}

interface SlackNode {
  id: string;
  required: number;
  slack: number;
  numAssigned: number;
  interval: IntervalNode;
  skill: SkillNode;
}

interface SlackEvent {
  [date: string]: {
    [id: string]: SlackNode;
  };
}

interface CalendarRef extends ScheduleComponent {
  slackEvents: SlackEvent;
  // Include any other properties or methods for the ref object if needed
}

const TimeOffRequestForm = forwardRef(
  (props: TimeOffRequestProps, ref: ForwardedRef<CalendarRef>) => {
    const {
      closeDialog,
      invalidDates,
      employees,
      environment,
      selectedOffice,
    } = props;

    const user: User = userVar();

    const managerAccess =
      user.role === Roles.MANAGER ||
      user.role === Roles.SCHEDULER ||
      user.role === Roles.ADMIN;

    const employee = managerAccess
      ? null
      : employees.find((e) => e.id === user.id);

    const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
      onError(error) {
        console.log(error);
      },
    });

    const [managerCreateEmployeeTimeOff] = useMutation(
      MANAGER_CREATE_EMPLOYEE_TIME_OFF,
      {
        update(cache, { data: { managerInitiateTimeOffV2 } }) {
          if (
            managerInitiateTimeOffV2.result.__typename ===
            "ManagerCreateEmployeeTimeOffResult"
          ) {
            const paginator = new DateTimePaginator(1);
            const element = managerInitiateTimeOffV2.result.employeeTimeOff;
            const start = new Date(element.start);
            const end = new Date(element.end);
            const dates = paginator.getQueryDateRanges(start, end);
            dates.forEach((dateArray) => {
              const variables = {
                end: dateArray[1].toISOString(),
                officeId: selectedOffice.id,
                start: dateArray[0].toISOString(),
                status: ["APPROVED", "REQUESTED"],
              };
              const strVariables = JSON.stringify(variables);
              cache.modify({
                fields: {
                  employeeTimeOff: (existing, { storeFieldName }) => {
                    if (storeFieldName.includes(strVariables)) {
                      const newTimeOffRef = cache.writeFragment({
                        data: element,
                        fragment: EMPLOYEE_TIME_OFF_FRAGMENT,
                      });
                      return [...existing, newTimeOffRef];
                    } else {
                      return existing;
                    }
                  },
                },
              });
            });

            const newSlacks = getSlackTransformer(
              -1,
              -1,
            )(managerInitiateTimeOffV2.result.slacks);

            if (ref && "current" in ref && ref.current) {
              ref.current.slackEvents = {
                ...ref.current.slackEvents,
                ...newSlacks,
              };
            }

            interface SlackElement {
              interval: {
                start: string;
                end: string;
              };
            }

            managerInitiateTimeOffV2.result.slacks.forEach(
              (element: SlackElement) => {
                const start = new Date(element.interval.start);
                const end = new Date(element.interval.end);
                const dates = paginator.getQueryDateRanges(start, end);
                dates.forEach((date) => {
                  const variables = {
                    issuesOnly: true,
                    office: selectedOffice.id,
                    pageEnd: date[1].toISOString(),
                    pageStart: date[0].toISOString(),
                  };
                  const strVariables = JSON.stringify(variables);
                  cache.modify({
                    fields: {
                      intervalSlacks: (existing, { storeFieldName }) => {
                        /**
                         * Modifier function to update the cached query `intervalSlacks`.
                         *
                         * Queries may be in the cache multiple times if they were called with
                         * different arguments. This callback function will be called on each of
                         * those cached fields so we check whether the variables we are interested
                         * in is contained in the `storeFieldName` which is the full key that includes
                         * the serialized variables. N.B. checking if a string includes a substring
                         * means that the order of the variables and the type (int vs string) matter.
                         * https://www.apollographql.com/docs/react/caching/cache-interaction#examples
                         * https://www.apollographql.com/docs/react/api/cache/InMemoryCache#modify
                         *
                         * @param {SlackNode[]} existing the array of object currently in the cache.
                         * @param {String} storeFieldName the serialized full key of the field including variable arguments
                         *
                         * @returns {SlackNode[]} the new object appended to existing or the existing array.
                         */
                        if (storeFieldName.includes(strVariables)) {
                          const newSlackRef = cache.writeFragment({
                            data: element,
                            fragment: SLACK_FRAGMENT,
                          });
                          return [...existing, newSlackRef];
                        } else {
                          return existing;
                        }
                      },
                    },
                  });
                });
              },
            );
          }
        },
        onCompleted(data) {
          if (
            data.managerInitiateTimeOffV2.result.__typename ===
            "ManagerCreateEmployeeTimeOffResult"
          ) {
            if (ref && "current" in ref && ref.current) {
              updateTimeOffEvent(
                ref.current,
                data.managerInitiateTimeOffV2.result.employeeTimeOff,
                null,
                selectedOffice.name,
              );
              updateScheduleFromOption(
                ref.current,
                data.managerInitiateTimeOffV2.result.impactedShifts,
                selectedOffice.name,
              );
              ref.current.refreshTemplates("dateHeaderTemplate");
            }

            toastShow("New Time Off Request created", "Success");
            closeDialog();
          } else {
            toastShow(data.managerInitiateTimeOffV2.result.message, "Error");
          }
        },
        onError(error) {
          console.log(error);
          toastShow(
            "Unable to create new time off request. Please check dates and try again.",
            "Error",
          );
          notifyDevelopers({
            variables: {
              message:
                "Error on MANAGER_CREATE_EMPLOYEE_TIME_OFF Mutation. Environment: " +
                environment +
                ". Graphql " +
                error,
            },
          });
        },
      },
    );

    const [createEmployeeTimeOff] = useMutation(CREATE_EMPLOYEE_TIME_OFF, {
      update(cache, { data: { createEmployeeTimeOff } }) {
        if (
          createEmployeeTimeOff.result.__typename ===
          "CreateEmployeeTimeOffResult"
        ) {
          const paginator = new DateTimePaginator(1);
          const element = createEmployeeTimeOff.result.employeeTimeOff;
          const start = new Date(element.start);
          const end = new Date(element.end);
          const dates = paginator.getQueryDateRanges(start, end);
          dates.forEach((dateArray) => {
            const variables = {
              end: dateArray[1].toISOString(),
              officeId: selectedOffice.id,
              start: dateArray[0].toISOString(),
              status: ["APPROVED", "REQUESTED"],
            };
            const strVariables = JSON.stringify(variables);
            cache.modify({
              fields: {
                employeeTimeOff: (existing, { storeFieldName }) => {
                  if (storeFieldName.includes(strVariables)) {
                    const newTimeOffRef = cache.writeFragment({
                      data: element,
                      fragment: EMPLOYEE_TIME_OFF_FRAGMENT,
                    });
                    return [...existing, newTimeOffRef];
                  } else {
                    return existing;
                  }
                },
              },
            });
          });
        }
      },
      onCompleted(data) {
        if (
          data.createEmployeeTimeOff.result.__typename ===
          "CreateEmployeeTimeOffResult"
        ) {
          if (ref && "current" in ref && ref.current) {
            updateTimeOffEvent(
              ref.current,
              data.createEmployeeTimeOff.result.employeeTimeOff,
              null,
              selectedOffice.name,
            );
          }

          toastShow("New Time Off Request created", "Success");
          closeDialog();
        } else {
          toastShow(data.createEmployeeTimeOff.result.message, "Error");
        }
      },
      onError(error) {
        console.log(error);
        toastShow(
          "Unable to create new time off request. Please check dates and try again. Type of request is required.",
          "Error",
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on CREATE_EMPLOYEE_TIME_OFF Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

    type Event = {
      typeId: string | null;
      workHours: number;
      comment: string;
      timeZone: string;
      employee: number | undefined;
      startDate: string;
      endDate: string;
      startTime: string | undefined;
      endTime: string | undefined;
      employeeId: number | undefined;
      managerId: number | undefined;
    };

    const handleSubmit = (event: any) => {
      const formattedStartDate = toIsoDate(event.startDate);
      const formattedEndDate = toIsoDate(event.endDate);

      let newEvent: Event = {
        typeId: event.type,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        startTime: event.isAllDay ? undefined : event.startTime,
        endTime: event.isAllDay ? undefined : event.endTime,
        workHours: parseInt(event.workHour),
        comment: event.comment,
        timeZone: event?.timezone,
        employee: undefined,
        employeeId: undefined,
        managerId: undefined,
      };

      if (managerAccess) {
        newEvent.employeeId = parseInt(event.employee.id);
        newEvent.managerId = parseInt(user.id);
        managerCreateEmployeeTimeOff({ variables: { input: { ...newEvent } } });
      } else {
        newEvent.employee = parseInt(user.id);
        createEmployeeTimeOff({ variables: { ...newEvent } });
      }
    };

    //check to see if user already has a request scheduled that date
    const checkInvalid = (date: Date) => {
      // alert(`check invalid date is ${date}`);
      if (employee !== user.id) {
        // alert("checkInvalid return false");
        return false;
      } else {
        const formatted = format(date, "MM/dd/yyyy");
        const checkResult = invalidDates.includes(formatted);
        // alert(`checkInvalid return: ${checkResult}`);
        return checkResult;
      }
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container direction="column" data-testid="time-off-request-form">
          <Grid item xs={12}>
            <Grid item container justifyContent="space-between">
              <Grid item xs={11}>
                <Typography data-testid="timeoff-form-title" variant="h3">
                  Add Calendar Event
                </Typography>
              </Grid>
              <Grid item xs={1} container justifyContent="flex-end">
                <IconButton
                  aria-label="close"
                  color="secondary"
                  size="small"
                  onClick={closeDialog}
                  data-testid="closeTimeOffFormShift"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TimeOffInputs
                handleSubmit={handleSubmit}
                selectedOfficeTimezone={selectedOffice?.timezone}
                managerAccess={managerAccess}
                checkInvalid={checkInvalid}
                employees={employees}
              />
            </Grid>
          </Grid>
        </Grid>
      </LocalizationProvider>
    );
  },
);

export default TimeOffRequestForm;
