import React from "react";

const PasswordStrengthIndicator = ({ password }) => {
  const requirements = [
    {
      label: "At least one uppercase character",
      condition: /[A-Z]/.test(password),
    },
    {
      label: "At least one lowercase character",
      condition: /[a-z]/.test(password),
    },
    {
      label: "At least one number",
      condition: /\d/.test(password),
    },
    {
      label: "At least one special character",
      condition: /[@$!%*?&]/.test(password),
    },
    {
      label: "At least eight characters",
      condition: password.length >= 8,
    },
    {
      label: "Cannot start with a whitespace",
      condition: !/^\s/.test(password),
    },
    {
      label: "Cannot end with a whitespace",
      condition: !/\s$/.test(password),
    },
  ];

  return (
    <div>
      {requirements.map((req, index) => (
        <div key={index} style={{ color: req.condition ? "green" : "red" }}>
          {req.label}
        </div>
      ))}
    </div>
  );
};

export default PasswordStrengthIndicator;
